import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CommentOutlined, Redeem } from "@material-ui/icons";
import { Rating, Skeleton } from "@material-ui/lab";
import { useMemo, useRef, useState } from "react";
import LikeButton from "components/LikeButton";
import ResourceProfile from "components/Resources/ResourceProfile";
import { cancelPropagation, getVideoIdFromYoutubeLink } from "utils";
import ItemCard from "components/ItemCard";
import ShowMore from "react-show-more-text";
import { useRouter } from "next/router";
import YoutubeVideo from "react-youtube";
import urlRegex from "url-regex-safe";
import Linkify from "react-linkify";
import moment from "moment";
import clsx from "clsx";
import CommentBox from "components/CommentBox";
import { useTranslation } from "i18n";
import MoreButton from "./MoreButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minHeight: 200,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  itemContainer: {
    marginTop: theme.spacing(2),
  },
  itemChips: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  relatedItems: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(2),
    "& > *": {
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
      },
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: theme.spacing(1),
    },

  },
  relatedItemsContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  showMore: {
    wordWrap: "break-word",
  },
}));

const ActivityItemBase = ({ itemId, liked, commentId, date, totalLikes, creator, content }) => {
  const router = useRouter();
  const classes = useStyles();
  const [videoReady, setVideoReady] = useState(false);
  const commentBox = useRef();
  const { t } = useTranslation();

  const youtubeId = useMemo(() => {
    const links = content.text?.match(urlRegex());
    if (links) {
      for (let i = 0; i < links.length; i = +1) {
        const link = links[i];
        const id = getVideoIdFromYoutubeLink(link);
        if (id) return id;
      }
    }
  }, [content.text]);

  const goToProfile = () => {
    router.push(`/profile?id=${creator.id}`);
  };

  const toggleCommentBox = () => {
    commentBox.current.toggle();
  };

  // const goToComment = () => {
  //   router.push(`/item/comment?commentId=${commentId}&itemId=${itemId}`);
  // };

  return (
    // <ChildrenFriendlyButton onMouseDown={cancelPropagation} onClick={goToComment}>
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Button onMouseDown={cancelPropagation} onClick={goToProfile}>
          <ResourceProfile
            roleId={creator.roleId}
            username={creator.name}
            avatarUrl={creator.asset}
          >
            <Typography variant="caption">
              {moment(date).fromNow()}
            </Typography>
          </ResourceProfile>
        </Button>
        <MoreButton commentId={commentId} />
      </Box>
      <Box flexGrow={1} my={2}>
        {
          content.valuation && (
            <Box>
              <Rating max={10} value={content.valuation} readOnly />
            </Box>
          )
        }
        <Linkify>
          <ShowMore
            className={classes.showMore}
            anchorClass="display-block"
            lines={5}
            more={t("SEE_MORE")}
            less={t("SEE_LESS")}
          >
            {content.text}
          </ShowMore>
        </Linkify>
        {
          youtubeId && (
            <Collapse in={videoReady}>
              <Box>
                <Divider variant="inset" />
                <YoutubeVideo
                  onReady={() => setVideoReady(true)}
                  videoId={youtubeId}
                  opts={{
                    width: "100%",
                  }}
                />
              </Box>
            </Collapse>
          )
        }
        {
          content.targetItem && (
            <>
              <Divider variant="inset" />
              <Box className={classes.itemContainer}>
                <Typography
                  variant="caption"
                  className={clsx("boldy", classes.itemChips)}
                >
                  {content.targetItem.chips.join(" - ")}
                </Typography>
                <Typography variant="body1" paragraph className="bold">
                  {content.targetItem.name}
                </Typography>
                <Box className={classes.relatedItemsContainer}>
                  <ItemCard
                    itemId={content.targetItem.id}
                    assetUrl={content.targetItem.asset}
                  />
                  <Box className={classes.relatedItems}>
                    {
                      content.targetItem.subItems.map((subItem) => (
                        <ItemCard
                          itemId={subItem.id}
                          title={subItem.name}
                          assetUrl={subItem.asset}
                          small
                        />
                      ))
                    }
                  </Box>
                </Box>
              </Box>
            </>
          )
        }
      </Box>
      <Box className={classes.actions}>
        <Box display="flex">
          <LikeButton
            totalLikes={totalLikes}
            itemId={itemId}
            commentId={commentId}
            initiallyLiked={liked}
          />
          <IconButton onClick={toggleCommentBox}>
            <CommentOutlined />
          </IconButton>
          <Tooltip title={t("GIVE_GIFTS")}>
            <IconButton disabled>
              <Redeem />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <CommentBox
        ref={commentBox}
        itemId={itemId}
        commentId={commentId}
        initialValue={`@${creator.name} `}
      />
    </Paper>
  );
};

ActivityItemBase.Skeleton = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.userbox}>
          <Skeleton variant="circle" width={50} height={50} />
          <Box className={classes.usernameInnerbox}>
            <Skeleton width={100} height={10} />
            <Skeleton width={35} height={10} />
          </Box>
        </Box>
      </Box>
      <Box flexGrow={1} my={2}>
        <Skeleton height={10} width="100%" />
        <Skeleton height={10} width="85%" />
        <Skeleton height={10} width="90%" />
        <Skeleton height={10} width="80%" />
      </Box>
    </Paper>
  );
};

ActivityItemBase.NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.userbox}>
          <Skeleton variant="circle" width={50} height={50} />
          <Box className={classes.usernameInnerbox}>
            <Skeleton width={100} height={10} />
            <Skeleton width={35} height={10} />
          </Box>
        </Box>
      </Box>
      <Box flexGrow={1} my={2}>
        <Typography variant="body2">
          {t("COMMENTS_NOT_FOUND")}
        </Typography>
        <Skeleton height={10} width="85%" />
        <Skeleton height={10} width="90%" />
        <Skeleton height={10} width="80%" />
      </Box>
    </Paper>
  );
};

export default ActivityItemBase;
