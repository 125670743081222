import { IconButton } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import React from "react";
import ShareButton from "components/ShareButton";
import { getClientsideHref } from "utils";

const MoreButton = ({ commentId }) => {
  const { host } = getClientsideHref();
  return (
    <ShareButton
      shareData={{
        url: `${host}/item/comments?id=${commentId}`,
      }}
    >
      <IconButton>
        <Share />
      </IconButton>
    </ShareButton>
  );
};

export default MoreButton;
