import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Box, Collapse, IconButton, TextField } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { toast } from "react-nextjs-toast";
import { useTranslation } from "i18n";
import { useFormik } from "formik";
import { items } from "api";
import * as yup from "yup";

const CommentBox = ({ itemId, commentId, initialValue, initiallyVisible }, ref) => {
  const [visible, setVisible] = useState(initiallyVisible);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const validationSchema = useMemo(() => yup.object({
    comment: yup
      .string()
      .min((initialValue?.length || 0) + 10, t("COMMENT_TOO_SHORT"))
      .max(5000, t("COMMENT_TOO_LONG")),
  }), []);

  const formik = useFormik({
    initialValues: {
      comment: `${initialValue} `,
    },
    validationSchema,
    onSubmit: async () => {
      const { error } = await items.createComment(itemId, inputRef.current.value, commentId);
      if (error) toast.notify(null, { type: "error", title: t("ERROR_WHILE_COMMENTING") });
      else {
        toast.notify(t("YOUR_COMMENT_IN_HOMEPAGE"), { type: "success", title: t("THANKS") });
        setVisible(false);
      }
    },
  });

  useEffect(() => {
    inputRef.current.value = initialValue;
  }, []);
    
  useImperativeHandle(ref, () => ({
    toggle: () => setVisible((v) => !v),
  }));
      
  return (
    <Collapse in={visible}>
      <Box mt={2} mb={2} maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            name="comment"
            id="comment"
            onChange={formik.handleChange}
            error={
              (formik.touched.comment && !!formik.errors.comment)
                   || formik.errors.response
            }
            helperText={(formik.touched.comment && formik.errors.comment) || formik.errors.response}
            fullWidth
            multiline
            inputRef={inputRef}
            label={t("WRITE_YOUR_COMMENT")}
            InputProps={{
              endAdornment: (
                <IconButton onClick={formik.handleSubmit}>
                  <Send />
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
    </Collapse>
  );
};

export default forwardRef(CommentBox);
