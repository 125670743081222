import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { cancelPropagation, copyToClipboard } from "utils";
import { useTranslation } from "i18n";

const ShareButton = ({ shareData, children, ...rest }) => {
  const [tooltip, setTooltip] = useState(false);
  const { t } = useTranslation();
  const share = async (e) => {
    cancelPropagation(e);
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        //
      }
    } else {
      copyToClipboard(shareData.url);
      setTooltip(true);
      setTimeout(() => {
        setTooltip(false);
      }, 1500);
    }
  };
      
  const shareableElement = React.cloneElement(children, {
    style: { cursor: "pointer" },
    onMouseDown: cancelPropagation,
    onClick: share,
    ...rest,
  });

  return (
    <Tooltip
      open={tooltip}
      PopperProps={{
        disablePortal: true,
      }}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      onClose={() => setTooltip(false)}
      title={t("COPIED_LINK")}
    >
      {shareableElement}
    </Tooltip>
  );
};

export default ShareButton;
