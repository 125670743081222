import React, { useEffect, useState } from "react";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import { items } from "api";
import { useAppState } from "store";
import { ThumbUp, ThumbUpOutlined } from "@material-ui/icons";

const LikeButton = ({
  itemId, commentId, initiallyLiked, totalLikes, onChangeLike, ...rest
}) => {
  const [isLiked, setLiked] = useState(initiallyLiked);
  const [loading, setLoading] = useState(false);
  const { cache, setCache } = useAppState();

  useEffect(() => {
    if (typeof cache.likes[itemId] === "boolean") {
      setLiked(cache.likes[itemId]);
    }
  }, [cache.likes, itemId]);

  const like = async () => {
    setLoading(true);
    const { error } = await items.setCommentReaction(itemId, commentId, 0);
    setLoading(false);
    if (!error) {
      setLiked(true);
      setCache({ ...cache, likes: { ...cache.likes, [itemId]: true } });
      if (onChangeLike) onChangeLike(1);
    }
  };

  const unlike = async () => {
    setLoading(true);
    const { error } = await items.deleteCommentReaction(itemId, commentId, 0);
    setLoading(false);
    if (!error) {
      setLiked(false);
      setCache({ ...cache, likes: { ...cache.likes, [itemId]: false } });
      if (onChangeLike) onChangeLike(-1);
    }
  };
  return (
    <Tooltip title="Like">
      <IconButton
        {...rest}
        disabled={loading}
        onClick={isLiked ? unlike : like}
        variant={isLiked ? "outlined" : "contained"}
      >
        <Badge
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={Number(totalLikes || 0) + Number(isLiked || 0)}
        >
          {!isLiked ? <ThumbUpOutlined /> : <ThumbUp color="primary" />}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default LikeButton;
